<template>
<div v-if="!!detail">
    <NavBottom />
    <div class="ptit">
        {{detail.Recruit_Title}}
    </div>
    <div class="datewrp">
        发于: {{detail.Recruit_Date}}
    </div>
    <div v-html="detail.Recruit_Content">
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            id: 0,
            detail: '',
            domin: this.domin,
        }
    },
    created() {
        this.id = this.$route.params.id
        this.getDetail()
    },
    methods: {
        getDetail() {
            this.wpost({
                url: '/Ajax/Member/getrecruitdetail',
                data: {
                    id: this.id,
                },
                succ: (data) => {
                    // console.log("result:", data)
                    // console.log("domin",this.domin)
                    this.detail = data.model
                    this.detail.Recruit_Content = data.model.Recruit_Content.replace(/src=\"\//ig, 'src=\"' + this.domin + '\/')
                }
            })
        },
    }
}
</script>

<style>
.ptit {
    margin: 10px;
    line-height: 1.6;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: justify;
}

.datewrp {
    text-align: right;
    margin: 10px 20px;
    font-size: 13px;
    color: #666;
}
</style>
